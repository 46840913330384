$(document).ready(function() {
  function calculateTotal() {
    const cost = parseFloat($('#cost').val()) || 0;
    const insurance = parseFloat($('#insurance').val()) || 0;
    const flexible = parseFloat($('#flexible').val()) || 0;
    const down = parseFloat($('#down').val()) || 0;

    const total = cost - insurance - flexible - down;
    $('#total').text(`$${total.toFixed(2)}`);
    $('#one-month').text(`$${total.toFixed(2)}`);
    $('#two-month').text(`$${(total / 2).toFixed(2)}`);
    $('#three-month').text(`$${(total / 3).toFixed(2)}`);
  }

  $('#cost, #insurance, #flexible, #down').on('input', calculateTotal);
});
