import Swiper from 'swiper';
import {Navigation, Pagination, EffectCreative, Autoplay, Scrollbar } from 'swiper/modules';
Swiper.use([Pagination, Navigation, EffectCreative, Autoplay, Scrollbar]);

const eventsSlider  = new Swiper('.testimonial-swiper-secondary',{
  slidesPerView: 1,
  autoHeight: true,
  loop: true,
  spaceBetween: 30,
  gap: 10,
  speed: 800,
  autoplay: {
    delay: 4000,
  },
  breakpoints: {
    650: {
      slidesPerView: 2,
    },
    1000: {
      slidesPerView: 1,
    },
    1400: {
      slidesPerView: 2,
    }
  },
  scrollbar: {
    el: '.swiper-scrollbar',
    draggable: true,
    hide: false,
  },
})
