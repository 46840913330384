import Swiper from 'swiper';
import { Navigation, Pagination, EffectFade, Autoplay } from 'swiper/modules';
Swiper.use([Pagination, Navigation, EffectFade, Autoplay]);

  const tabs = document.querySelectorAll('.tab');
  const slides = document.querySelectorAll('.swiper-testimonials .swiper-slide');

  const swiper = new Swiper('.swiper-testimonials', {
    spaceBetween: 30,
    slidesPerView: 1,
    speed: 600,
    autoplay: {
      delay: 4000,
    },
    navigation: {
      nextEl: '.testimonials-swiper-button-next',
      prevEl: '.testimonials-swiper-button-prev',
    },
  });

  function showSlidesForTab(tabIndex) {
    slides.forEach((slide, index) => {
      if (tabIndex === 'tab-0') {
        slide.classList.add('active-slide');
      } else if (slide.getAttribute('data-tab') === tabIndex) {
        swiper.slideTo(index);
        slide.classList.add('active-slide');
      } else {
        slide.classList.remove('active-slide');
      }
    });

    tabs.forEach(tab => {
      if (tab.getAttribute('data-tab') === tabIndex) {
        tab.classList.add('active-tab');
      } else {
        tab.classList.remove('active-tab');
      }
    });
  }

  showSlidesForTab('tab-0');

  tabs.forEach(tab => {
    tab.addEventListener('click', function () {
      const tabIndex = this.getAttribute('data-tab');
      showSlidesForTab(tabIndex);
      swiper.update();
    });
  });
