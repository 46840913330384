(function() {
  const $faqs = $('.faq');
  const $faqTab = $('.faq_tab');

  $faqTab.click(function (e) {
    e.preventDefault();
    let tabId = $(this).data('tab');
    showFaq(tabId);
  });

  function showFaq(tabId) {
    console.log('Removing active class from all solutions');
    $faqTab.removeClass('active-faq');
    $faqs.hide();
    $(`#${tabId}`).show();
    $(`[data-tab="${tabId}"]`).addClass('active-faq');
  }

  $faqs.hide();
  showFaq('faq0');

  $('.accordion summary').on('click', function() {
    var $currentAccordion = $(this).parent();
    $('.accordion').not($currentAccordion).removeAttr('open');
  });
})();
