import Swiper from 'swiper';
import {Navigation, Pagination, EffectCreative, Autoplay } from 'swiper/modules'
Swiper.use([Pagination, Navigation, EffectCreative, Autoplay]);

const imageSwiper = new Swiper('.image-swiper', {
  spaceBetween: 10,
  slidesPerView: 1.1,
  centeredSlides: true,
  roundLengths: true,
  loop: true,
  breakpoints: {
    900: {
      slidesPerView: 1.5,
      spaceBetween: 30,
    },
  },

  navigation: {
    nextEl: '.image-swiper-button-next',
    prevEl: '.image-swiper-button-prev',
  },
});
