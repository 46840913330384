import Swiper from 'swiper';
import {Navigation, Pagination, EffectCreative, Autoplay } from 'swiper/modules'
Swiper.use([Pagination, Navigation, EffectCreative, Autoplay]);

const singleSwiper = new Swiper('.single-swiper', {
  spaceBetween: 10,
  slidesPerView: 1,
  centeredSlides: true,
  roundLengths: true,
  loop: true,
  navigation: {
    nextEl: '.single-swiper-button-next',
    prevEl: '.single-swiper-button-prev',
  },
});
